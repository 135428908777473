<template>
  <div class="position">
    <div class="top">
      <p class="fs"><span>{{val.jobName}}</span><span>{{val.wageMax}}</span></p>
      <p>{{val.areaName+'·'+val.address}}</p>
    </div>
    <div class="bottom">
      <div class="company">
        <img :src="val.enterpriseLogo || '/head-portrait2.png'" alt="" />
      </div>
      <div class="introduce">
        <p>
          {{val.enterpriseName}}<img
            src="@/assets/img/Index_JobSeeker/icon6.png"
            alt=""
          />
        </p>
        <p>{{val.enterpriseSizeName}}</p>
      </div>
    </div>
    <div class="connect" v-if="connect == 'true'">沟通</div>
  </div>
</template>
<script>
export default {
  name: "Position",
  props: {
    connect: {
      type: String,
    },
    val:{
      type:Object
    }
  },
};
</script>
<style scoped>
.position {
  padding: 16px;
  width: 386px;
  height: 180px;
  background: #ffffff;
  box-sizing: border-box;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
}
.position .top {
  border-bottom: 1px solid #eeeeee;
}
.position .top p:nth-child(1) {
  height: 25px;
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 10px;
}
.position .top p:nth-child(1) span:nth-child(1) {
  color: #333333;
}
.position .top p:nth-child(1) span:nth-child(2) {
  color: #ff2647;
}
.position .top p:nth-child(2) {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.position .bottom {
  display: flex;
  padding-top: 16px;
}
.position .bottom .company {
  width: 52px;
  height: 52px;
  margin-right: 8px;
}
.position .bottom .company img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.position .bottom .introduce p:nth-child(1) {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  line-height: 22px;
  margin-bottom: 10px;
}
.position .bottom .introduce p:nth-child(1) img {
  vertical-align: text-bottom;
  margin-left: 4px;
  width: 18px;
  height: 18px;
}
.position .bottom .introduce p:nth-child(2) {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.position .connect {
  position: absolute;
  width: 76px;
  height: 32px;
  background: linear-gradient(171deg, #7cc3ff 0%, #3580df 100%);
  border-radius: 16px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
  right: 16px;
  bottom: 10px;
}
</style>
