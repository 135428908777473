import axios from '@/utils/http'

// 获取简历列表
export const resumeState = function (data) {
  return axios.post('/power/cms/resumeStatus/getDownList', data)
}

// 修改简历状态
export const editStatus = function (data) {
  return axios.post('/power/cms/resumeStatus/editStatus', data)
}

// 查看当前简历状态
export const currStatus = function (data) {
  return axios.post('/power/cms/resumeStatus/getStatus', data)
}

// 新增教育经历
export const educationAdd = function (data) {
  return axios.post('/power/cms/educational/saveOrUpdate', data)
}
// 新增求职基本信息
export const baseInfoAdd = function (data) {
  return axios.post('/power/cms/jobSeekerBasicInfo/saveOrUpdate', data)
}

// 获取求职基本信息
export const getBaseInfo = function (data) {
  return axios.get('/power/cms/jobSeekerBasicInfo/detail', { params: data })
}

// 获取求职期望
export const getJobExpectation = function (data) {
  return axios.get('/power/cms/jobExpectation/detail', { params: data })
}

// 新增求职期望
export const jobExpectationAdd = function (data) {
  return axios.post('/power/cms/jobExpectation/add', data)
}

// 获取学历下拉列表
export const educationDrop = function (dictType) {
  return axios.get('/power/dict/data/type/'+dictType)
}

// 获取城市列表
export const getCity = function (data) {
  return axios.get('/power/cms/city/listPage',{ params: data })
}

// 新增工作经历
export const workExperienceAdd = function (data) {
  return axios.post('/power/cms/workExperience/saveOrUpdate', data)
}

// 查看工作经历
export const workExperienceDetail = function (data) {
  return axios.get('/power/cms/workExperience/detail',{ params: data })
}

// 删除工作经历
export const workExperienceDel = function (id) {
  return axios.delete('/power/cms/workExperience/remove/'+id)
}

// 新增项目经历
export const projectExperienceAdd = function (data) {
  return axios.post('/power/cms/projectExperience/saveOrUpdate', data)
}

// 查看项目经历
export const projectExperienceDetail = function (data) {
  return axios.get('/power/cms/projectExperience/listPage',{ params: data })
}

// 获取单条项目经历
export const projectExperienceOne = function (id) {
  return axios.get('/power/cms/projectExperience/detail/'+id)
}

// 删除项目经历
export const projectExperienceDel = function (id) {
  return axios.delete('/power/cms/projectExperience/remove/'+id)
}

// 新增教育经历
export const educationalAdd = function (data) {
  return axios.post('/power/cms/educational/saveOrUpdate', data)
}

// 获取教育经历
export const educationalDetail = function (data) {
  return axios.get('/power/cms/educational/detail',{ params: data })
}

// 删除教育经历
export const educationalDel = function (id) {
  return axios.delete('/power/cms/educational/remove/'+id)
}

// 新增简历
export const resumeAdd = function (data) {
  return axios.post('/power/cms/jobSweekResume/add', data)
}

// 新增简历
export const resumeList = function (data) {
  return axios.get('/power/cms/jobSweekResume/listPage', data)
}
// 简历列表
export const resumeAllList = function (data) {
  return axios.get('/power/cms/jobSweekResume/resume/listPage', data)
}

// 删除简历
export const resumeRemove = function (data) {
  return axios.delete('/power/cms/jobSweekResume/remove', { data })
}

// 置顶简历
export const resumeTop = function (data) {
  return axios.get('/power/cms/jobSweekResume/topping', { params: data })
}

// 获取职位字典
export const jobListPage = function (data) {
  return axios.get('/power/cms/job/listPage', { params: data })
}
